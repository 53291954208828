export default {
  title: 'Nota de Direção',
  driverScore: {
    none: 'Não disponível',
    A: 'Excelente',
    B: 'Bom',
    C: 'Regular',
    D: 'Melhorar',
    E: 'Ruim',
  },
  label: {
    score: 'Nota de Direção',
    break: 'Frenagem',
    turn: 'Curvas',
    throttle: 'Aceleração',
    overspeeding: 'Velocidade',
  },
  helpText: {
    score: {
      none: 'Não disponível',
      A: 'A condução do motorista está excelente!',
      B: 'O motorista está indo bem.',
      C: 'O motorista pode melhorar.',
      D: 'O motorista pode melhorar.',
      E: 'O motorista precisa melhorar.',
    },
    break: {
      none: 'Não disponível',
      A: 'Para manter sua nota, o motorista deve manter a distância segura dos outros carros.',
      B: 'Para melhorar sua nota, o motorista deve manter a distância segura dos outros carros.',
      C: 'Risco de derrapagens e colisões traseiras. Para melhorar sua nota, o motorista deve manter a distância segura dos outros carros.',
      D: 'Risco de derrapagens e colisões traseiras. Para melhorar sua nota, o motorista deve manter a distância segura dos outros carros.',
      E: 'Risco de derrapagens e colisões traseiras. Para melhorar sua nota, o motorista deve manter a distância segura dos outros carros.',
    },
    turn: {
      none: 'Não disponível',
      A: 'Para manter sua nota, o motorista deve sempre ajustar a velocidade antes de curvas.',
      B: 'Para melhorar sua nota, o motorista deve sempre ajustar a velocidade antes de curvas.',
      C: 'Risco de perder o controle da direção. Para melhorar sua nota, o motorista deve sempre ajustar a velocidade antes de curvas.',
      D: 'Risco de perder o controle da direção. Para melhorar sua nota, o motorista deve sempre ajustar a velocidade antes de curvas.',
      E: 'Risco de perder o controle da direção. Para melhorar sua nota, o motorista deve sempre ajustar a velocidade antes de curvas.',
    },
    throttle: {
      none: 'Não disponível',
      A: 'Para manter sua nota, o motorista deve acelerar suavemente, de forma progressiva.',
      B: 'Para melhorar sua nota, o motorista deve acelerar suavemente, de forma progressiva.',
      C: 'Risco de não ter tempo de reagir a imprevistos. Para melhorar sua nota, o motorista deve acelerar suavemente, de forma progressiva.',
      D: 'Risco de não ter tempo de reagir a imprevistos. Para melhorar sua nota, o motorista deve acelerar suavemente, de forma progressiva.',
      E: 'Risco de não ter tempo de reagir a imprevistos. Para melhorar sua nota, o motorista deve acelerar suavemente, de forma progressiva.',
    },
    overspeeding: {
      none: 'Não disponível',
      A: 'Para manter sua nota, o motorista deve manter a velocidade adequada à via e às condições climáticas.',
      B: 'Para melhorar sua nota, o motorista deve manter a velocidade adequada à via e às condições climáticas.',
      C: 'Risco de colisões frontais. Para melhorar sua nota, o motorista deve manter a velocidade adequada à via e às condições climáticas.',
      D: 'Risco de colisões frontais. Para melhorar sua nota, o motorista deve manter a velocidade adequada à via e às condições climáticas.',
      E: 'Risco de colisões frontais. Para melhorar sua nota, o motorista deve manter a velocidade adequada à via e às condições climáticas.',
    },
  },
};
