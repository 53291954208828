import { i18next } from '../translate/i18n';

export const formatStatus = status => {
  switch (status) {
    case  'PAID':
    case  'BONUS':
    case  'BALANCE_CREDITED':
      return 'success';
    case 'MANUALLY_PAID':
    case 'PENDING':
    case 'REFUNDED':
    case 'SCHEDULED':
      return 'warning';
    case 'CANCELED':
    case 'ERROR':
    case 'DISPUTED':
    case 'STATUS_ERROR':
      return 'danger';
    case 'NESTED_NEGOTIATED':
      return 'negotiation'
    default:
      return 'primary';
  }
};

export const formatKind = kind => {
  switch (kind) {
    case 'FIRST_PAYMENT':
    case 'FIRST_PAYMENT_EXCHANGE':
    case 'PRE_PAYMENT_PROMOTIONAL':
      return 'success';
    case 'FINE':
    case 'NIC':
      return 'warning';
    case 'MAINTENANCE':
    case 'THEFT':
    case 'RECOVER':
    case 'SUBSCRIPTION_TERMINATION':
    case 'LICENCE_PLATE_ITEMS':
    case 'CAR_WASH':
    case 'CAR_KEY':
    case 'BAD_USE_ACTIVE':
    case 'SEIZED':
    case 'MISAPPROPIATION':
    case 'THEFT_COPARTICIPATION_MAXIMUM':
    case 'THEFT_COPARTICIPATION_MINIMUN':
    case 'COLLISION_COPARTICIPATION_KOVI':
    case 'FULL_COLLECTION':
    case 'BAD_USE_INACTIVE':
    case 'NO_SHOW':
    case 'DELAY_PREVENTIVE':
    case 'DBS':
      return 'danger';
    case 'OTHERS':
      return 'dark';
    case 'NEGOTIATION':
    case 'INSTALLMENT':
      return 'negotiation'
    case 'RECURRENCY':
    case 'DESMOB_PRE_PAYMENT':
    case 'PRE_PAYMENT_RTO':
    case 'PRE_PAYMENT':
    case 'PRE_PAYMENT_RESERVE':
    case 'DEPOSIT':
    default:
      return 'primary';
  }
};

export const formatStatusTed = kind => {
  switch (kind) {
    case 'success':
      return 'success';
    case 'pending':
      return 'warning';
    case 'failed':
    case 'refused':
      return 'danger';
    case 'approved':
      return 'primary';
    default:
      return 'dark';
  }
};

export const parseKind = kind => {
  return i18next.t(['payments.enumParseKind.' + kind, 'payments.enumParseKind.DEFAULT']);
};

export const parseStatusTed = status => {
  switch (status) {
    case 'pending':
      return i18next.t('payments.enumParseStatusTed.PENDING');

    case 'approved':
      return i18next.t('payments.enumParseStatusTed.APPROVED');

    case 'refused':
      return i18next.t('payments.enumParseStatusTed.REFUSED');

    case 'success':
      return i18next.t('payments.enumParseStatusTed.SUCCESS');

    case 'failed':
      return i18next.t('payments.enumParseStatusTed.FAILED');

    default:
      return i18next.t('payments.enumParseStatusTed.DEFAULT');
  }
};


export const enumStatus = [
  {text: i18next.t('payments.enumStatus.BONUS'), value: 'BONUS'},
  {text: i18next.t('payments.enumStatus.PAID'), value: 'PAID'},
  {text: i18next.t('payments.enumStatus.MANUALLY_PAID'), value: 'MANUALLY_PAID'},
  {text: i18next.t('payments.enumStatus.PENDING'), value: 'PENDING'},
  {text: i18next.t('payments.enumStatus.REFUSED'), value: 'REFUSED'},
  {text: i18next.t('payments.enumStatus.REFUNDED'), value: 'REFUNDED'},
  {text: i18next.t('payments.enumStatus.CANCELED'), value: 'CANCELED'},
  {text: i18next.t('payments.enumStatus.ERROR'), value: 'ERROR'},
  {text: i18next.t('payments.enumStatus.SCHEDULED'), value: 'SCHEDULED'},
];


export const enumStatusTranslate = (status) => {
  const statusEnum = {
    PAID: i18next.t('payments.enumStatus.PAID'),
    MANUALLY_PAID: i18next.t('payments.enumStatus.MANUALLY_PAID'),
    PENDING: i18next.t('payments.enumStatus.PENDING'),
    REFUSED: i18next.t('payments.enumStatus.REFUSED'),
    REFUNDED: i18next.t('payments.enumStatus.REFUNDED'),
    CANCELED: i18next.t('payments.enumStatus.CANCELED'),
    ERROR: i18next.t('payments.enumStatus.ERROR'),
    SCHEDULED: i18next.t('payments.enumStatus.SCHEDULED'),
    BALANCE_CREDITED: i18next.t('payments.enumStatus.BALANCE_CREDITED'),
    DISPUTED: i18next.t('payments.enumStatus.DISPUTED'),
    NESTED_NEGOTIATED: i18next.t('payments.enumStatus.NESTED_NEGOTIATED'),
  };

  return statusEnum[status] || status;
}

export const enumStatusContestation = (status) => {
  const statusEnum = {
    PENDING: i18next.t('payments.enumStatusContestation.OPEN'),
    SUCCESS: i18next.t('payments.enumStatusContestation.FINISHED'),
  };

  return statusEnum[status] || null;
}

export const enumMethod = [
  {text: i18next.t('payments.enumMethod.creditCard'), value: 'credit_card'},
  {text: i18next.t('payments.enumMethod.debitCard'), value: 'debit_card'},
  {text: i18next.t('payments.enumMethod.billet'), value: 'boleto'},
  {text: i18next.t('payments.enumMethod.pix'), value: 'pix'},
  {text: i18next.t('payments.enumMethod.oxxo'), value: 'oxxo'},
];

export const enumType = [
  {text: i18next.t('payments.enumType.debit'), value: 'DEBIT'},
  {text: i18next.t('payments.enumType.credit'), value: 'CREDIT'}];

export const formatGateway = gateway => {
  switch (gateway) {
    case 'adyen':
      return 'Adyen';
    case 'stark_bank':
      return 'Stark Bank';
    default:
      return '';
  }
}
