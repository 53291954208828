import { PAYMENT_METHOD_TYPE } from '../enums/paymentMethod';
import { i18next } from '../translate/i18n';

export const formatStatus = status => {
  return status === 'ACTIVE'
    ? 'success'
    : status === 'REMOVED'
      ? 'secondary'
      : status === 'EXPIRED'
        ? 'warning'
        : 'primary';
};

export const expiryMonth = [
  {value: '01', text: '1'},
  {value: '02', text: '2'},
  {value: '03', text: '3'},
  {value: '04', text: '4'},
  {value: '05', text: '5'},
  {value: '06', text: '6'},
  {value: '07', text: '7'},
  {value: '08', text: '8'},
  {value: '09', text: '9'},
  {value: '10', text: '10'},
  {value: '11', text: '11'},
  {value: '12', text: '12'},
];

export const expiryYear = [
  {value: '2018', text: '2018'},
  {value: '2019', text: '2019'},
  {value: '2020', text: '2020'},
  {value: '2021', text: '2021'},
  {value: '2022', text: '2022'},
  {value: '2023', text: '2023'},
  {value: '2024', text: '2024'},
  {value: '2025', text: '2025'},
  {value: '2026', text: '2026'},
  {value: '2027', text: '2027'},
  {value: '2028', text: '2028'},
  {value: '2029', text: '2029'},
  {value: '2030', text: '2030'},
  {value: '2031', text: '2031'},
  {value: '2032', text: '2032'},
  {value: '2033', text: '2033'},
];

export const paymentMethodType = (payment_method_type) => {
  let result_value = '';

  switch (payment_method_type) {
    case PAYMENT_METHOD_TYPE.OXXO:
      result_value = 'Oxxo';
      break;
    case PAYMENT_METHOD_TYPE.CREDITCARD:
      result_value = 'Cartão de Crédito';
      break;
    case PAYMENT_METHOD_TYPE.PIX:
        result_value = 'PIX';
        break;
    default:
      result_value = 'Boleto Bancário';
      break;
  }

  return result_value;
};

export const paymentMethodTranslate = {
  'credit_card': i18next.t('payments.enumMethod.creditCard'),
  'pix': i18next.t('payments.enumMethod.pix'),
  'boleto': i18next.t('payments.enumMethod.billet'),
  'oxxo': i18next.t('payments.enumMethod.oxxo'),
}
