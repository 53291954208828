export default {
  document: 'CPF',
  phone: 'Telefone',
  isActive: 'Ativo',
  selectLabel: 'Aperte \'enter\' para selecionar',
  isActiveBooking: 'Aluguel ativo',
  noResult: 'Não foram encontrados com os seus termos de busca.',
  lastSearch: 'Últimas pesquisas acessadas',
  placeholder: 'Buscar por nome, CPF, e-mail ou telefone',
  categoryOptions: {
    cars: 'Veículos',
    drivers: 'Motoristas',
  },
  error: {
    ops: 'Ocorreu um erro!',
  },
  warning: {
    hey: 'Ei, Cuidado!',
    sameRoute: 'Você está tentando acessar uma busca na qual você já se encontra no resultado.'
  },
}
