import status from '@graphql/schedules/enum/status';

export const statusToHuman = (value) => {
  switch (value) {
    case status.TO_SCHEDULE:
      return 'Para agendar';

    case status.SCHEDULED:
      return 'Agendado';

    case status.DOING:
      return 'Em andamento';

    case status.NO_SHOW:
      return 'Não foi';

    case status.DONE:
      return 'Pronto';

    case status.CANCELED:
      return 'Cancelado';

    default:
      return 'Sem status';
  }
}

export const statusToBootstrapVariant = (value) => {
  switch (value) {
    case status.TO_SCHEDULE:
      return 'secondary';

    case status.SCHEDULED:
    case status.DOING:
      return 'warning';

    case status.NO_SHOW:
      return 'danger';

    case status.DONE:
      return 'success';

    case status.CANCELED:
      return 'warning';

    default:
      return 'light';
  }
};

export const listInternalComments = [
  {
    value: 'Motorista não compareceu ao agendamento',
    text: 'Motorista não compareceu ao agendamento',
  },
  {
    value: 'Veículo sofreu sinistro',
    text: 'Veículo sofreu sinistro',
  },
  {
    value: 'Veiculo devolvido antes do agendamento',
    text: 'Veiculo devolvido antes do agendamento',
  },
];

export const sortListByStatus = (list) => {
  return list.sort((a, b) => {
    if (a.status === status.TO_SCHEDULE && b.status !== status.TO_SCHEDULE) {
      return -1;
    }
    if (a.status !== status.TO_SCHEDULE && b.status === status.TO_SCHEDULE) {
      return 1;
    }
    return 0;
  });
}
