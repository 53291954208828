import Vue from 'vue';
import { formatStatus } from '@utils/driver';
import { statusToBootstrapClass } from '@utils/transaction';
import { enumStatusTranslate } from '@utils/wallet'
import { enumStepStatusTranslate } from '@utils/signup'
import Formatters from '@utils/formatters';
import { statusToHuman, statusToBootstrapVariant } from '@utils/schedule';
import { paymentMethodType } from '@utils/payment-method';
import { TYPES, STATUS, BOOKING_STATUS, CONDUCTOR } from '@enums/schedule'

Vue.filter('filterPaymentMethodType', paymentMethodType);

Vue.filter('format_address', Formatters.formatAddress);

Vue.filter('format_phone', Formatters.formatIntlPhone);

Vue.filter('format_document', Formatters.formatDocument);

Vue.filter('format_carPlate', Formatters.formatDocument);

Vue.filter('format_date', Formatters.formatDate);

Vue.filter('format_date_tz', Formatters.formatDateTz);

Vue.filter('format_from_now', Formatters.formatDateFromNow);

Vue.filter('format_from_now_utc', Formatters.formatDateFromNowUtc);

Vue.filter('empty', (result) => {
  if (!result) return '-';
  return result;
});

Vue.filter('proof_name', value => {
  if (!value) return 'Nome inválido';
  const arrValue = value.split('/');
  return arrValue[arrValue.length - 1];
});

Vue.filter('booking_status', (booking) => {
  const status = booking ? typeof booking === 'string' ? booking : booking.status : ''

  if (status === 'ACTIVE') {
    return 'Sim';
  } else if (status === 'PENDING') {
    return 'Aguardando carro';
  } else {
    return 'Não';
  }
});

Vue.filter('format_boolean', (value) => {
  return value === true ? 'Sim' : 'Não'
});

Vue.filter('status_variant', value => formatStatus(value));

Vue.filter('transaction_status', value => enumStatusTranslate(value));

Vue.filter('signup_step_status', value => enumStepStatusTranslate(value));

Vue.filter('transaction_status_variant', value => statusToBootstrapClass(value))

Vue.filter('money', Formatters.formatMoney);

Vue.filter('money_intl', Formatters.formatMoneyIntl)

Vue.filter('plural', (value, singular, plural) => {
  const word = value === 1 ? singular : plural;

  return `${value} ${word}`;
});

Vue.filter('schedule_odometer_status', value => value.metadata[`odometer_at_${value.status}`]);

Vue.filter('schedule_status_to_human', value => statusToHuman(value));

Vue.filter('schedule_status_to_bootstrap_variant', value => statusToBootstrapVariant(value));

Vue.filter('format_schedule', (value, scheduleType) => {
  let enabledTypes = ['type', 'status', 'bookingStatus', 'conductor'];

  if (!enabledTypes.includes(scheduleType)) return value

  if (scheduleType === 'type' && TYPES.hasOwnProperty(value)) return TYPES[value]
  else if (scheduleType === 'status' && STATUS.hasOwnProperty(value)) return STATUS[value]
  else if (scheduleType === 'bookingStatus' && BOOKING_STATUS.hasOwnProperty(value)) return BOOKING_STATUS[value]
  else if (scheduleType === 'conductor' && CONDUCTOR.hasOwnProperty(value)) return CONDUCTOR[value]
  else return value
})
