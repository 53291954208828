import { AUTH_REDIRECT_LOCAL_STORAGE } from '@enums/router';
import { Auth } from 'aws-amplify';
import Store from '@store';

const { commit } = Store;

export const awsAuthConfig = () => ({
  domain: `${process.env.AWS_COGNITO_HOSTED_UI}`,
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: `${location.origin}/auth/login`,
  redirectSignOut: `${location.origin}/auth/logout`,
  responseType: 'code'
});

export const clearStorage = () => window.localStorage.removeItem(AUTH_REDIRECT_LOCAL_STORAGE);

export const localPath = () => window.localStorage.getItem(AUTH_REDIRECT_LOCAL_STORAGE);

export const setLocalPath = (path) => window.localStorage.setItem(AUTH_REDIRECT_LOCAL_STORAGE, path);

export const getSignOutURL = () => {
  const { domain, redirectSignIn, responseType } = awsAuthConfig();
  const { userPoolWebClientId } = Auth.configure();

  return `https://${domain}/logout?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${userPoolWebClientId}`;
}

export const getUserDisplayName = (user) => {
  try {
    const [{ displayName }] = (JSON.parse(user.name));

    return displayName;
  } catch (e) {
    return '';
  }
}

export const signOut = async () => {
  await Auth.signOut({
    global: true,
  });

  commit('user/logout');

  await window.location.replace(getSignOutURL());
}
