import axios from 'axios';
import store from '../store'
import router from '@router';

const fleetRegulationProvider = {
  install(Vue) {
    const fleetRegulationProvider = axios.create({
      baseURL: process.env.KOVI_FLEET_REGULATION_API_GATEWAY || 'https://fleet-regulation.fleet.kovi.io/drivers',
      headers: { 'Content-Type': 'application/json' }
    });

    fleetRegulationProvider.interceptors.request.use(config => {
      const authToken = store.state.user.authToken;
      if (authToken) {
        config.headers.Authorization = authToken;
      }
      return config;
    });

    fleetRegulationProvider.interceptors.response.use(
      res => res,
      error => {
        if (error.response.status === 401) {
          router.push({ name: `AuthLogin` });
        }

        return Promise.reject(error);
      }
    );

    Vue.prototype.$fleetRegulationProvider = fleetRegulationProvider;
  },
};

export default fleetRegulationProvider
