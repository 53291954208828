import { i18next } from "@translate/i18n";

export const statusToBootstrapClass = status =>
  ['REFUSED', 'ERROR', 'CANCELED'].includes(status)
    ? 'danger'
    : ['PENDING', 'SCHEDULED'].includes(status)
      ? 'warning'
      : ['PAID', 'REFUNDED', 'BONUS', 'MANUALLY_PAID'].includes(status)
        ? 'success'
        : 'primary';

export const transactionKind = [
  { text: i18next.t('payments.transaction.transactionKind.PRE_PAYMENT'), value: 'PRE_PAYMENT' },
  { text: i18next.t('payments.transaction.transactionKind.PRE_PAYMENT_RTO'), value: 'PRE_PAYMENT_RTO' },
  { text: i18next.t('payments.transaction.transactionKind.PRE_PAYMENT_PROMOTIONAL'), value: 'PRE_PAYMENT_PROMOTIONAL' },
  { text: i18next.t('payments.transaction.transactionKind.DESMOB_PRE_PAYMENT'), value: 'DESMOB_PRE_PAYMENT' },
  { text: i18next.t('payments.transaction.transactionKind.FIRST_PAYMENT'), value: 'FIRST_PAYMENT' },
  { text: i18next.t('payments.transaction.transactionKind.FIRST_PAYMENT_EXCHANGE'), value: 'FIRST_PAYMENT_EXCHANGE' },
  { text: i18next.t('payments.transaction.transactionKind.RECURRENCY'), value: 'RECURRENCY' },
  { text: i18next.t('payments.transaction.transactionKind.FINE'), value: 'FINE' },
  { text: i18next.t('payments.transaction.transactionKind.NIC'), value: 'NIC' },
  { text: i18next.t('payments.transaction.transactionKind.MAINTENANCE'), value: 'MAINTENANCE' },
  { text: i18next.t('payments.transaction.transactionKind.THEFT'), value: 'THEFT' },
  { text: i18next.t('payments.transaction.transactionKind.RECOVER'), value: 'RECOVER' },
  { text: i18next.t('payments.transaction.transactionKind.INSTALLMENT'), value: 'INSTALLMENT' },
  { text: i18next.t('payments.transaction.transactionKind.SUBSCRIPTION_TERMINATION'), value: 'SUBSCRIPTION_TERMINATION' },
  { text: i18next.t('payments.transaction.transactionKind.OTHERS'), value: 'OTHERS' },
  { text: i18next.t('payments.transaction.transactionKind.LICENCE_PLATE_ITEMS'), value: 'LICENCE_PLATE_ITEMS' },
  { text: i18next.t('payments.transaction.transactionKind.CAR_WASH'), value: 'CAR_WASH' },
  { text: i18next.t('payments.transaction.transactionKind.CAR_KEY'), value: 'CAR_KEY' },
  { text: i18next.t('payments.transaction.transactionKind.BAD_USE_ACTIVE'), value: 'BAD_USE_ACTIVE' },
  { text: i18next.t('payments.transaction.transactionKind.SEIZED'), value: 'SEIZED' },
  { text: i18next.t('payments.transaction.transactionKind.MISAPPROPIATION'), value: 'MISAPPROPIATION' },
  { text: i18next.t('payments.transaction.transactionKind.THEFT_COPARTICIPATION_MAXIMUM'), value: 'THEFT_COPARTICIPATION_MAXIMUM' },
  { text: i18next.t('payments.transaction.transactionKind.THEFT_COPARTICIPATION_MINIMUN'), value: 'THEFT_COPARTICIPATION_MINIMUN' },
  { text: i18next.t('payments.transaction.transactionKind.COLLISION_COPARTICIPATION_KOVI'), value: 'COLLISION_COPARTICIPATION_KOVI' },
  { text: i18next.t('payments.transaction.transactionKind.FULL_COLLECTION'), value: 'FULL_COLLECTION' },
  { text: i18next.t('payments.transaction.transactionKind.BAD_USE_INACTIVE'), value: 'BAD_USE_INACTIVE' },
  { text: i18next.t('payments.transaction.transactionKind.NO_SHOW'), value: 'NO_SHOW' },
  { text: i18next.t('payments.transaction.transactionKind.DELAY_PREVENTIVE'), value: 'DELAY_PREVENTIVE' },
  { text: i18next.t('payments.transaction.transactionKind.DBS'), value: 'DBS' },
  { text: i18next.t('payments.transaction.transactionKind.PRE_PAYMENT_RESERVE'), value: 'PRE_PAYMENT_RESERVE'},
  { text: i18next.t('payments.transaction.transactionKind.DEPOSIT'), value: 'DEPOSIT'},
];

export const transactionKindEnum = {
  NEGOTIATION: 'NEGOTIATION',
  PRE_PAYMENT: 'PRE_PAYMENT',
  PRE_PAYMENT_RTO: 'PRE_PAYMENT_RTO',
  PRE_PAYMENT_PROMOTIONAL: 'PRE_PAYMENT_PROMOTIONAL',
  PRE_PAYMENT_RESERVE: 'PRE_PAYMENT_RESERVE',
  DESMOB_PRE_PAYMENT: 'DESMOB_PRE_PAYMENT',
  FIRST_PAYMENT: 'FIRST_PAYMENT',
  FIRST_PAYMENT_EXCHANGE: 'FIRST_PAYMENT_EXCHANGE',
  RECURRENCY: 'RECURRENCY',
  FINE: 'FINE',
  NIC: 'NIC',
  MAINTENANCE: 'MAINTENANCE',
  THEFT: 'THEFT',
  RECOVER: 'RECOVER',
  INSTALLMENT: 'INSTALLMENT',
  SUBSCRIPTION_TERMINATION: 'SUBSCRIPTION_TERMINATION',
  OTHERS: 'OTHERS',
  LICENCE_PLATE_ITEMS: 'LICENCE_PLATE_ITEMS',
  CAR_WASH: 'CAR_WASH',
  CAR_KEY: 'CAR_KEY',
  BAD_USE_ACTIVE: 'BAD_USE_ACTIVE',
  SEIZED: 'SEIZED',
  MISAPPROPIATION: 'MISAPPROPIATION',
  THEFT_COPARTICIPATION_MAXIMUM: 'THEFT_COPARTICIPATION_MAXIMUM',
  THEFT_COPARTICIPATION_MINIMUN: 'THEFT_COPARTICIPATION_MINIMUN',
  COLLISION_COPARTICIPATION_KOVI: 'COLLISION_COPARTICIPATION_KOVI',
  FULL_COLLECTION: 'FULL_COLLECTION',
  BAD_USE_INACTIVE: 'BAD_USE_INACTIVE',
  NO_SHOW: 'NO_SHOW',
  DELAY_PREVENTIVE: 'DELAY_PREVENTIVE',
  DBS: 'DBS',
  DEPOSIT: 'DEPOSIT'
};

export const transactionStatus = [
  { text: i18next.t('payments.transaction.transactionStatus.PENDING'), value: 'PENDING' },
  { text: i18next.t('payments.transaction.transactionStatus.REFUSED'), value: 'REFUSED' },
  { text: i18next.t('payments.transaction.transactionStatus.PAID'), value: 'PAID' },
  { text: i18next.t('payments.transaction.transactionStatus.ERROR'), value: 'ERROR' },
  { text: i18next.t('payments.transaction.transactionStatus.CANCELED'), value: 'CANCELED' },
  { text: i18next.t('payments.transaction.transactionStatus.REFUNDED'), value: 'REFUNDED' },
  { text: i18next.t('payments.transaction.transactionStatus.MANUALLY_PAID'), value: 'MANUALLY_PAID' },
  { text: i18next.t('payments.transaction.transactionStatus.BALANCE_CREDITED'), value: 'BALANCE_CREDITED' },
  { text: i18next.t('payments.transaction.transactionStatus.BONUS'), value: 'BONUS' },
  { text: i18next.t('payments.transaction.transactionStatus.NESTED_NEGOTIATED'), value: 'NESTED_NEGOTIATED' },
  { text: i18next.t('payments.transaction.transactionStatus.DISPUTED'), value: 'DISPUTED' },
];

export const status = {
  PENDING: 'PENDING',
  REFUSED: 'REFUSED',
  PAID: 'PAID',
  BONUS: 'BONUS',
  ERROR: 'ERROR',
  CANCELED: 'CANCELED',
  REFUNDED: 'REFUNDED',
  MANUALLY_PAID: 'MANUALLY_PAID',
  SCHEDULED: 'SCHEDULED',
  DISPUTED: 'DISPUTED',
  BALANCE_CREDITED: 'BALANCE_CREDITED',
};

export const paymentType = {
  CREDIT: 'credit',
  DEBIT: 'debit',
  CARD: 'card',
  BILLET: 'billet',
};

export const parseStatusTransactionEvent = (status) => {
  return i18next.t(`payments.enumParseTransactionEvents.${status}`);
}
