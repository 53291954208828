import { getCurrentRegion } from '@utils/helper-region';
import { formatPrice } from '@utils/numbers';

export const formatMoney = (value, currency = 'R$') => formatPrice(currency, value);

export const formatMoneyIntl = (value) => {
  if (!value) {
    return;
  }

  const region = getCurrentRegion();
  return Intl.NumberFormat(region.lcid, { style: 'currency', currency: region.currency }).format(
    value / 100);
};
