import ClipboardJS from 'clipboard';
import { i18next } from '../translate/i18n';
import Vue from 'vue';
export const copyText = (id, text) => {
  const clipboard = new ClipboardJS(`#${id}`,
        {
          text: () => text
        }
      );
      clipboard.on('success', (e) => {
        Vue.prototype.$snotify.success(i18next.t('payments.labels.clipboardSuccess'));
        e.clearSelection();
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        Vue.prototype.$snotify.error(i18next.t('payments.labels.clipboardError'));
        clipboard.destroy();
      });
  }
