import apollo from '@utils/graphql';

export const query = (graphqlQuery, variables) => apollo.cache.reset() && apollo.query({
  query: graphqlQuery,
  variables,
});

export const mutate = (graphqlMutation, variables) => apollo.mutate({
  mutation: graphqlMutation,
  variables,
});
