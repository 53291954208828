import { KOVI_ADMIN } from '@utils/helper-local-storage';
import { COUNTRIES } from '@src/enums/regions';
import LanguageLCID from '@utils/lcid-languages.json';
import CurrencyLCID from '@utils/lcid-currency.json';

export function getCurrentRegion() {
  const hasKoviLS = localStorage.getItem(KOVI_ADMIN);
  const region = hasKoviLS
    ? JSON.parse(hasKoviLS).user.region
    : COUNTRIES.BR;
  let country;
  try { country = region.split(':')[0]; } catch (err) { country = 'BR'; }
  const lcid = LanguageLCID[country];
  const currency = CurrencyLCID[country];

  return {
    country,
    lcid,
    currency,
    region,
  };
}

export function isMexico() {
  return getCurrentRegion().country === COUNTRIES.MX;
}

export function isBrazil() {
  return getCurrentRegion().country === COUNTRIES.BR;
}
