import { i18next } from '../translate/i18n'

export const enumStepStatusTranslate = (status) => {
  const statusEnum = {
    LEAD: i18next.t('drivers.texts.basicRegister'),
    PROSPECT: i18next.t('drivers.texts.basicRegister'),
    BGC: i18next.t('drivers.texts.bgcChecking'),
    BGC_REJECTED: i18next.t('drivers.texts.bgcChecking'),
    APPS_PENDING: i18next.t('drivers.texts.appsPending'),
    DOCS_PENDING: i18next.t('drivers.texts.waitingDocuments'),
    DOCS_CHECKING: i18next.t('drivers.texts.checkingDocuments'),
    DOCS_REJECTED: i18next.t('drivers.labels.docsReject'),
    DOCS_APPROVED: i18next.t('drivers.texts.docsApproved'),
    INVITE_PENDING: i18next.t('drivers.labels.waitingInvite'),
    INVITE_SENT: i18next.t('drivers.labels.inviteSend'),
    INVITE_EXPIRED: i18next.t('drivers.labels.inviteExpired'),
    PLAN_PENDING: i18next.t('drivers.labels.planPending'),
    PAYMENT_PENDING: i18next.t('drivers.texts.waitingPaymentPending'),
    PAYMENT_PENDING_PAYMENT: i18next.t('drivers.texts.waitingPaymentPendingPayment'),
    DELIVERY_PENDING: i18next.t('drivers.texts.waitingScheduled'),
    DELIVERY_SCHEDULED: i18next.t('drivers.labels.deliveryScheduled'),
    DELIVERY_NOSHOW: i18next.t('drivers.texts.driverNoShow'),
    WAITING_LIST: i18next.t('drivers.texts.waitingList'),
    NO_OPERATION: i18next.t('drivers.texts.noOperation'),
    FINISHED: i18next.t('drivers.texts.finished'),
  };

  if (!statusEnum[status]) return status;
  return statusEnum[status];
}
