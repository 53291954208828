import { COUNTRIES } from '@src/enums/regions.js';
import alerts from './alerts/index.json';
import contract from './contract/index.json';
import corrective from './maintenance/corrective.json';
import drivers from './drivers/index.json';
import driversBooking from './drivers/booking.json';
import driversScheduled from './drivers/schedules.json';
import driversSignup from './drivers/signup.json';
import fleet from './fleet/index.json';
import fleetProfile from './fleet/profile.json';
import fleetFines from './fleet/fines.json';
import LanguagesLCID from '@utils/lcid-languages.json';
import maintenance from './maintenance/index.json';
import menu from './menu/index.json';
import modalBooking from './modals/booking.json';
import orderModal from './modals/order.json';
import modalProviderAdd from './modals/provider.json';
import modalSchedule from './modals/schedule.json';
import modalOrderConfirm from './modals/order-confirm.json';
import modalVehicleDelivery from './modals/vehicle-delivery.json';
import modalSignup from './modals/signup.json';
import modalWaitingDelivery from './modals/waiting-delivery.json';
import modalPreConfirmSchedule from './modals/pre-confirm-schedule.json';
import planModal from './modals/plan.json';
import orders from './orders/index.json';
import payments from './payments/index.json';
import providers from './providers/index.json';
import place from './place/index.json';
import shared from './shared/index.json';
import recurrency from './recurrency/index.json';
import safety from './safety/index.json';
import component from './component';
import chat from './chat/index.json';
import checklist from './checklist/index.json';
import transaction from './transaction/index.json';
import users from './users/index.json';
import modalUserAdd from './modals/user-add.json';

export const messages = {
  [LanguagesLCID[COUNTRIES.BR]]: {
    translations: {
      component,
      alerts,
      contract,
      corrective,
      drivers,
      driversBooking,
      driversScheduled,
      driversSignup,
      fleet,
      fleetProfile,
      fleetFines,
      maintenance,
      menu,
      modalBooking,
      orderModal,
      modalOrderConfirm,
      modalProviderAdd,
      recurrency,
      modalVehicleDelivery,
      modalSignup,
      modalWaitingDelivery,
      modalPreConfirmSchedule,
      planModal,
      orders,
      payments,
      providers,
      shared,
      modalSchedule,
      place,
      safety,
      chat,
      checklist,
      transaction,
      users,
      modalUserAdd
    }
  }
};
