export function class2obj(classInstance = null) {
  if (!classInstance)
    throw new Error('Class instance not passed');

  let obj = {
    ...classInstance
  };

  Object.keys(obj)
    .forEach(key => {
      if (key.charAt(0) === '_') {
        obj[key.substr(1)] = obj[key];
        delete obj[key];
      }
    });

  return obj;
}
