import ApolloClient from 'apollo-client';
// import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { version } from '../../package';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { Auth } from 'aws-amplify';
import Store from '@store';

// Create http link
const ApolloHttpLink = createUploadLink({
  uri: process.env.KOVI_GRAPHQL_URL,
});

// Create local cache
const ApolloCache = new InMemoryCache();

// Create auth structure to send user token
const ApolloAuth = setContext(async (_, { headers }) => {
  const session = await Auth.currentSession();
  const authToken = session.getIdToken().getJwtToken();
  const region = Store.getters['user/region'];

  return {
    ...headers,
    headers: {
      authorization: authToken ? authToken : '',
      'apollographql-client-name': 'rental',
      'apollographql-client-version': version,
      Region: region
    },
  };
});

// Set new apollo client
const Apollo = new ApolloClient({
  cache: ApolloCache,
  link: ApolloAuth.concat(ApolloHttpLink),
});

// Process apollo queries
const mutate = Apollo.mutate;
Apollo.mutate = async (args) => {
  const mutationBody = args.mutation.loc.source.body;
  const mutationName = mutationBody.match(/mutation (.*?)([\s]?)\(/i)[1] || 'Update';

  // Hijack data and track mutations events
  window.analytics.track(mutationName, args.variables);

  // Return payload
  return mutate(args);
};

export default Apollo;
