const normalizeWhiteSpaces = (value) => value.trim().replace(/\s+/g, ' ');

export const getAllWords = (input) => {
  const match = input.match(/\w/g);
  if (match) {
    return match.join('');
  }
  return '';
};

export const normalizeString = (s) => normalizeWhiteSpaces(s)
.normalize('NFC')
.replace(/[\u0300-\u036F]/g, '')
.replace(/[^\w-]+/gi, '');

export const fixStrings = normalizeString;

export const cleanSpecialChars = (s) => normalizeString(s)
.replace(/[^\w-]+/g, ' ');

export const toCamel = (s) => fixStrings(s.replace(/([_-][a-z])/gi, (c) => c.toUpperCase()
.replace(/[_-]/g, '')));

export const toSnakeCase = (s) => s.replace(/\W+/g, ' ')
.split(/ |\B(?=[A-Z])/)
.map((w) => w.toLowerCase())
.join('_');

export const toUnderscore = (s) => fixStrings(s.split(/(?=[A-Z])/).join('_').toLocaleLowerCase());

export const toTitleCase = (phrase) => {
  return phrase
  .toLowerCase()
  .replace(/_/gi, ' ')
  .split(' ')
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join(' ');
};
