import axios from 'axios';
import store from '../store'

const fleetProvider = {
  install(Vue) {
    const fleetApi = axios.create({
      baseURL: process.env.KOVI_FLEET_API_GATEWAY,
      headers: { 'Content-Type': 'application/json' }
    });

    fleetApi.interceptors.request.use(config => {
      const authToken = store.state.user.authToken;
      if (authToken) {
        config.headers.Authorization = authToken;
      }
      return config;
    });

    Vue.prototype.$fleetProvider = fleetApi;
  },
};

export default fleetProvider
