import { isMexico } from '@utils/helper-region';

export const formatCarPlate = (value) => {
  if (!value) return '';

  if (isMexico()) return `${value}`.toUpperCase();

  return `${value}`.replace(/^(\w{3})(\w{4})/, '$1-$2');
}

export const clearBrandName = (carModel) => {
  const brandNames = [
    'FORD',
    'TOYOTA',
    'VOLKSWAGEN',
    'CHEVROLET',
    'FIAT',
    'RENAULT',
    'NISSAN',
    'HYUNDAI',
  ];

  return carModel.split('_').filter((b) => !brandNames.includes(b)).join('');
}
