export const formatPrice = (currency, value) => {
  let format

  currency === "R$" ?
    format = {
      currency: "BRL",
      locale: "pt-BR"
    }
  :
    format = {
      currency: "MXN",
      locale: "es-MX"
    }

  return new Intl.NumberFormat(format.locale, {
    style: 'currency',
    currency: format.currency
  }).format(value/100);
}; 

export const formatNumber = value => {
  let val = (value / 1).toFixed(0);
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const formatPhone = value => {
  return value.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})/, '+$1 $2 $3-$4');
};

export const formatVat = value => {
  return value.toString().replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

export const formatCpf = value => {
  if (!value) return value;
  return value.toString().replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export const calcDateDiff = (start_date, end_date, formatted = false) => {
  let result = '';
  let result_months = '';
  let result_days = '';
  if (end_date) {
    result_months = this.$moment(end_date).diff(this.$moment(start_date), 'months');
    result_days = this.$moment(end_date).diff(this.$moment(start_date), 'days');
  } else {
    result_months = this.$moment()
      .utc()
      .diff(this.$moment(start_date), 'months');
    result_days = this.$moment()
      .utc()
      .diff(this.$moment(start_date), 'days');
  }
  if (result_months < 1) {
    result = formatted ? result_days : result_days + ' dias';
  } else if (result == 1) {
    result = formatted ? 1 : 'um mês';
  } else {
    result = formatted ? result_months.toFixed(0) : result_months.toFixed(0) + ' meses';
  }
  return result;
};
