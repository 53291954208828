import Moment from 'moment';
import momentI18n from '@src/lib/moment';
import moment from 'moment-timezone';
export const formatDate = (date, format = 'DD/MM/YYYY') => {
  if (!date) return '';
  try {
    return Moment(date).format(format);
  } catch (e) {
    return '';
  }
};
export const formatDateTz = (date, format = 'DD/MM/YYYY') => {
  if (!date) return '';
  try {
    return moment.tz(date, moment.tz.guess()).format(format);
  } catch (e) {
    return '';
  }
};
export const formatDateTimeZone = (date, format = 'DD/MM/YYYY', timezone = 'America/Sao_Paulo') => {
  if (!date) return '';
  try {
    return moment.tz(date, timezone).format(format);
  } catch (e) {
    return '';
  }
};
export const formatDateFromNow = (date) => {
  if (!date) return '';
  try {
    return Moment(date).fromNow();
  } catch (e) {
    return '';
  }
};
export const formatDateFromNowUtc = (date) => {
  if (!date) return '';
  try {
    return Moment.utc(date).humanize();
  } catch (e) {
    return '';
  }
};

export const daysFrom = (date) => {
  const now = Moment();
  const desiredDate = Moment(date);

  return now.diff(desiredDate, 'days')
}
